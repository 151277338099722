<template>
	<div class="send-quest-main">
		<div class="" style="position: relative;height: 88px;">
			<top-bar></top-bar>
		</div>
		<div class="data-content">
			<div class="title-text">问卷访问链接</div>
			<div class="url-text"><a class="touch-hover color-blue" :href="linkurl" target="_blank">{{linkurl}}</a></div>
			<div class="title-text ">短信内容</div>
			<div class="url-text">【姓名】你好！云南联通诚邀【企业名称】参与填写调研问卷《【问卷名称】》，问卷地址：【问卷地址】，企业专属邀请码：【邀请码】</div>
			<div class="send-tip-content">
				<div class="send-title">发送提示:</div>
				<div class="send-text">1、运营商对非验证码短信管理严格，部分用户无法收到短信或接收会有较长延时。</div>
				<div class="send-text">2、根据规定，群发类短信只能在每天9点到22点之间发送。</div>
			</div>
			<div class="btn-content flex flex-col-center">
				<button class="enter-btn" @click="sendImmediately">立即发送</button>
			</div>
			<div class="send-qun-content">
<!--				<div class="qun-title" v-show="!sendData.company">短信群发中.....</div>-->
				<div class="qun-list flex flex-col-center" v-for="(item, index) in sendData" :key="item.id">
					<span class="fs-text fs-text-abs">{{item.company}}</span>
					<span class="fs-text fs-text-abs">{{item.truename}}</span>
					<span class="fs-text">{{item.mobile}}</span>
					<span :class="['status-text', item.status=='发送成功'?'text-greed':'text-red']">{{item.status}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import topBar from "@/views/questionnaire/components/top-bar.vue"
	import {naire_getqueinfo, send_message} from "../../request/questionApi";
	export default{
		components: {
			topBar
		},
		data(){
			return{
				// linkUrl: 'https://www.lzzcloud.com/mobile/aggregate.php',//问卷访问链接
				sendData: [],
				linkurl:''
			}
		},
		mounted() {
			this.$store.commit('SET_QUESTION_STATU', 2);
			this.getData()
		},
		methods:{
			  getData() {
				// 发起异步请求获取数据
				this.$api.naire_getqueinfo({qid: this.$route.query.qid}).then(res => {
				  console.log(res);
				  // 更新数据
				  this.linkurl = res.data.linkurl;
				  console.log(this.linKurl);
				}).catch(error => {
				  console.error('Error fetching data:', error);
				});
			  },
			//获取状态
			getStatus(num) {
				if (num==0) return "发送失败";
				if (num==1) return "发送成功";
			},
			sendImmediately(){
				const qid = this.$route.query.qid
				this.$api.send_message({qid:qid}).then(res=>{
				  this.sendData = res.data
				})
			}
		},
  }
</script>

<style lang="scss" scoped>
	.send-quest-main{
		.data-content{margin: 18px;background-color: #fff;padding: 30px 20px;
			.title-text{font-size: 16px;color: #242D33;}
			.url-text{margin: 18px 0 40px 0;padding: 18px;background: #F6F7F9;border: 1px solid #EDF1F4;color: #242D33;font-size: 12px;}
			.color-blue{color: #3984F5;}
			.send-tip-content{background: #F6F7F9;border: 1px solid #EDF1F4;margin-bottom: 40px;padding: 24px 18px;
				.send-title{font-size: 12px;font-weight: bold;color: #6C7880;}
				.send-text{font-size: 12px;color: #6C7880;line-height: 26px;}
			}
			.btn-content{margin-bottom: 40px;
				.enter-btn{width: 120px;height: 44px;background: #3984F5;border-radius: 4px;line-height: 44px;text-align: center;color: #fff;margin-right: 10px;font-size: 14px;}
				.save-btn{width: 120px;height: 44px;background: #EEF1F3;border-radius: 4px;line-height: 44px;text-align: center;color: #6C7880;font-size: 14px;}
			}
			.send-qun-content{height: 320px;background: #F6F7F9;border: 1px solid #EDF1F4;padding: 24px 18px;overflow: scroll;box-sizing: border-box;
				.qun-title{font-size: 14px;font-weight: bold;color: #242D33;}
				.fs-text{font-size: 12px;color: #242D33;line-height: 32px;margin-right: 20px;}
				.fs-text-abs{position: relative;}
				.fs-text-abs::after{position: absolute;content: '/';top: 0;right: -10px;color: #242D33;font-size: 12px;}
				.text-greed{color: #04CD83;}
				.text-red{color: #FD5451;}
			}
		}
		
	}
	
</style>